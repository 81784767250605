<template>
  <header>
    <div class="buttons">
      <Button
        @btn-click="$emit('move-previous')"
        :text="'Previous'"
        :color="showAddTask ? 'red' : 'green'"
      />
      <Button
        @btn-click="$emit('move-next')"
        :text="'Next'"
        :color="showAddTask ? 'red' : 'green'"
      />
    </div>
  </header>
</template>

<script>
import Button from "./Button";

export default {
  name: "Header",
  props: {
    title: String,
    showAddTask: Boolean,
    countQuestions: Number,
    countUnansweredQuestions: Number,
  },
  components: {
    Button,
  },
  computed: {
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.buttons {
  display: block;
  text-align: center;
  width: 100%;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
