<template>
  <footer>
    <div v-if="allAnswered">
      <Button
        @btn-click="$emit('submit-answers')"
        :text="'Submit answers'"
        :color="'red'"
      />
      <div v-if="displayAnswers">
        <h1>Summary</h1>
        <p class="summary-text">{{ summary }}</p>
        <p class="summary-text">{{ subSummary }}</p>
        <p>
          Thanks for playing, and thanks for the great 5 years! Good luck and
          have (more!) fun!
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import Button from "./Button";

export default {
  name: "Footer",
  props: {
    countQuestions: Number,
    countUnansweredQuestions: Number,
    tasks: Array,
    allAnswered: Boolean,
    countAnsweredCorrectly: Number,
    displayAnswers: Boolean,
  },
  components: {
    Button,
  },
  emits: ["submit-answers", "restart"],
  computed: {
    subSummary() {
      return `You got ${
        this.countAnsweredCorrectly === this.countQuestions ? "all" : ""
      } ${this.countAnsweredCorrectly} right.`;
    },
    summary() {
      if (this.countQuestions) {
        let percentageOk = this.countAnsweredCorrectly / this.countQuestions;

        let assessment = "Ugh. Well, better luck next time, I guess!";

        if (percentageOk < 0.2)
          assessment = "Ok. Word of advice. For the next 5 years - keep notes";
        else if (percentageOk < 0.4)
          assessment =
            "Not horrible, but maybe next time just guess at random?";
        else if (percentageOk < 0.6)
          assessment = "Congratulations! You must have been taking notes!";
        else if (percentageOk < 0.8)
          assessment = "Not bad, not bad at all! Congratulations!";
        else if (percentageOk < 0.99)
          assessment =
            "A true son of the Digital Factory. You should consider writing its history for the benefit of all!";
        else if (percentageOk < 1.01) assessment = "Perfection!";

        return assessment;
      } else {
        return "";
      }
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
a {
  color: #333;
}

.summary-text {
  margin: 1rem;
}

footer {
  margin-top: 30px;
  text-align: center;
}
</style>
