<template>
  <div class="container">
    <router-view :tasks="tasks" @answer-selected="answerSelected" />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      tasks: [
        {
          id: 0,
          text: "Ieva",
          day: "",
          video: "ieva.mp4",
          av: "https://drive.google.com/file/d/1k5o8L0fdPsrnmhpzYXVm8E6X92jVw7V3/preview",
          reminder: false,
          question: "What is at the top of the data hierarchy of Signal",
          options: [
            "mc²",
            "my companies",
            "there is no right answer here",
            "all companies",
            "my board",
          ],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 1,
          text: "Alan",
          day: "",
          video: "alan.mp4",
          av: "https://drive.google.com/file/d/1x-eKejp-k1w-b-NaU-f5qXco7B7De5lZ/preview",
          question:
            "Who, do you think ate the most snacks while visiting google HQ",
          options: [
            "Alan",
            "Rob Brooks",
            "Glen Cathey",
            "Matthew Fite",
            "yourself, Remco",
          ],
          validAnswers: [0],
          answerProvied: [],
        },
        {
          id: 2,
          text: "Niels M.",
          day: "",
          video: "nielsm.mp4",
          av: "https://drive.google.com/file/d/18tXiiZwZ3lEYq6L1Aa7rhlamtSUm-7h6/preview",
          question: "Name the one city that isn't a project in data ocean",
          options: ["Radom", "Monte Carlo", "Amstelveen", "Vilvoorde"],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 3,
          text: "Wilbert",
          day: "",
          video: "wilbert.mp4",
          av: "https://drive.google.com/file/d/18cGeeOTy9aZjv9Ja9EjHLCUiQHvArn9X/preview",
          question: "Name the company from Florida we worked on data in 2018",
          options: [
            "People Salary",
            "Salary Board",
            "People Ticker",
            "Data Ticker",
          ],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 4,
          text: "Rob",
          day: "",
          video: "rob.mp4",
          av: "https://drive.google.com/file/d/1HqS2wMKsdLQrHMRTeHiSgITAMqplo4vX/preview",
          question:
            "How many iterations did we complete while working on allbirds data prep",
          options: ["7", "11", "5", "13"],
          validAnswers: [1],
          answerProvied: [],
        },
        {
          id: 5,
          text: "Conny",
          day: "",
          video: "conny.mp4",
          av: "https://drive.google.com/file/d/1RXkecJt_ghGI3KrybLXOhOhEbi6ysTDM/preview",
          question:
            "When Conny picked up ownership of myRandstadapp, which countries was it live in?",
          options: [
            "Belgium, Portugal, Italy, Switzerland",
            "Belgium, Australia, New Zeland, Portugal",
            "Belgium, Portugal, Switzerland, Australia",
            "Belgium, Italy, Switzerland, Australia",
          ],
          validAnswers: [0],
          answerProvied: [],
        },
        {
          id: 6,
          text: "Sri",
          day: "",
          video: "sri.mp4",
          av: "https://drive.google.com/file/d/1lDTvXUv75Obs86K6JGVTy_xYM0_A13ER/preview",
          question:
            "In 2020, we built a POC for a global VMS service with Randstad Germany. Which Customer did we do the POC for?",
          options: ["Siemens", "Bear", "ABB", "Amazon"],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 7,
          text: "Glen",
          day: "",
          video: "glen.mp4",
          av: "https://drive.google.com/file/d/1zXO9DjG_Eae4nY_5K9M3xKiYkyepCYJi/preview",
          question:
            "Do you remember the name of the company Google wanted to partner with and outsource the development of the chatbot to?",
          options: ["TCS", "Cognizant", "EPAM", "Cap Gemini"],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 8,
          text: "Ximo",
          day: "",
          video: "ximo.mp4",
          av: "https://drive.google.com/file/d/1q-VOt0uzElzsNyIxN8vArfR5oZlDK98a/preview",
          question:
            "We played an unusual sport in one of our integration events. What sport was that?",
          options: ["Cricket", "Chess boxing", "Baseball", "Rugby"],
          validAnswers: [0],
          answerProvied: [],
        },
        {
          id: 9,
          text: "Łukasz",
          day: "",
          video: "lukasz.mp4",
          av: "https://drive.google.com/file/d/1Oev-AlM-bx6Pb1du923L-NW3pupVMmCP/preview",
          question:
            "What serverless solution we used to integrate with Planday and Shiftboard?",
          options: [
            "Kubernetes",
            "AWS Fargate",
            "AWS Lambda",
            "GCP Cloud Functions",
          ],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 10,
          text: "Rene",
          day: "",
          video: "rene.mp4",
          av: "https://drive.google.com/file/d/1_Iu3bNgJYfWRIiNCyvKSa0D-xICJUYE1/preview",
          question:
            "In 2019 we had a number of workstreams. Ieva created a presentation with an additional element 'F'. What was this special element?",
          options: [
            "Funding",
            "Fun",
            "Foundations",
            "Future oriented datasets",
          ],
          validAnswers: [1],
          answerProvied: [],
        },
        {
          id: 11,
          text: "Jeroen",
          day: "",
          video: "jeroen.mp4",
          av: "https://drive.google.com/file/d/1H738QfHRc6YU_6gXYizdSmZsYtRz-hna/preview",
          question:
            "We had a party in Apres Ski bar, and there was a theme. What was the theme?",
          options: ["Disney", "Marvel", "Mariokart", "Game of thrones"],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 12,
          text: "Helen",
          day: "",
          video: "helen.mp4",
          av: "https://drive.google.com/file/d/1NVgtX-j4MagChhkPMEceDAOyW8Zu6RBU/preview",
          question: "What other mean of transportation did we use?",
          options: ["HTTP/2 and gRPC", "Ice skates", "Sleds", "Pannenkoekslee"],
          validAnswers: [1],
          answerProvied: [],
        },
        {
          id: 13,
          text: "Andy",
          day: "",
          video: "andy.mp4",
          av: "https://drive.google.com/file/d/1Vb91ElZpWJCHeEMfXaakJFVmtVzt9qZW/preview",
          question:
            "From 2017 to 2022, what was the top thing we achieved? Mark all that apply!",
          options: [
            "Improvements to how we manage data",
            "Local to global shift",
            "Frontoffice updates",
            "Market gains",
          ],
          validAnswers: [0, 1, 2, 3],
          answerProvied: [],
        },
        {
          id: 14,
          text: "Mar",
          day: "",
          video: "mar.mp4",
          av: "https://drive.google.com/file/d/14oXUV3gkUnMxb0Y5BfYO8ZVjnZBxke9d/preview",
          question:
            "What feature did we come up with while coming back from Brussels?",
          options: [
            "the inbox with notifications",
            "the job leads search page",
            "the job leads kanban",
            "the sales actions kanban",
          ],
          validAnswers: [3],
          answerProvied: [],
        },
        {
          id: 15,
          text: "Stijn",
          day: "",
          video: "stijn.mp4",
          av: "https://drive.google.com/file/d/1rLZXf-2UmGRNhMCIZguUox6mm9WGUFTL/preview",
          question:
            "How much data do we have in the google big query currently?",
          options: ["0.5PB", "1.1PM", "1.8PB", "2.4PB"],
          validAnswers: [3],
          answerProvied: [],
        },
        {
          id: 16,
          text: "Dorien",
          day: "",
          video: "dorien.mp4",
          av: "https://drive.google.com/file/d/1pe-Am_n0T0IkgEfl_oCGYij8WNqJzK87/preview",
          question: "Who missed the cycling tour for the fundraising event?",
          options: ["Astrid", "Marloes", "Łukasz", "Edwin"],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 17,
          text: "Niels vW",
          day: "",
          video: "nielsvw.mp4",
          av: "https://drive.google.com/file/d/1vnGJUZEe3YZtx5CMB6rfmIlB_S_CxQwc/preview",
          question:
            "How many countries do we serve through Textkernel, Burningglass and Jobdigger",
          options: ["10", "11", "12", "13"],
          validAnswers: [3],
          answerProvied: [],
        },
        {
          id: 18,
          text: "Bianca",
          day: "",
          video: "bianca.mp4",
          av: "https://drive.google.com/file/d/1hEn9LZxgqXauwsT7WtPljT1PSi5MmhHA/preview",
          question: "When was our first meeting and who was there?",
          options: [
            "March 2017 together with Esther Raaijmakers",
            "May 2017 together with Erna Jansen",
            "March 2018: together with Suzanne Kahle",
          ],
          validAnswers: [1],
          answerProvied: [],
        },
        {
          id: 19,
          text: "Philippe",
          day: "",
          video: "philippe.mp4",
          av: "https://drive.google.com/file/d/1iI_Lb36IjVG-NcxOBV1wi23kjES7l5k2/preview",
          question:
            "In 2020 we had three major global cyberincidents. What kind of attacks did we suffer?",
          options: ["DDOS", "Ransomware", "Identity theft"],
          validAnswers: [0, 1],
          answerProvied: [],
        },
        {
          id: 20,
          text: "WB",
          day: "",
          video: "wb.mp4",
          av: "https://drive.google.com/file/d/1k-rDQidrW36qtNObCw2bM2nnLv-9ncCR/preview",
          question:
            "What was the license plate of the allbirds on tour caravan?",
          options: ["BA-02-GAR", "BC-01-DE", "DE-01-AB", "AB-01-DE "],
          validAnswers: [3],
          answerProvied: [],
        },
        {
          id: 21,
          text: "Prashant",
          day: "",
          video: "prashant.mp4",
          av: "https://drive.google.com/file/d/1UQFZpU_44x2UnNR3HMcG82yoozQRz3nl/preview",
          question: "Which country went life with the myRandstad app first",
          options: ["Portugal", "Belgium", "Switzerland", "Poland"],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 22,
          text: "Emma",
          day: "",
          video: "emma.mp4",
          av: "https://drive.google.com/file/d/10F9ce4ZdbusDofo-T7oitFCosDRAadWG/preview",
          question:
            "Welche der volgenden gehören nicht zu unseren offiziellen AI prinziples ",
          options: [
            "Human forward",
            "Fairness and inclusivity by design",
            "Responsibility",
            "Privacy and security",
          ],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 23,
          text: "Meredith",
          day: "",
          video: "meredith.mp4",
          av: "https://drive.google.com/file/d/13WQ6Y6xomr091NHwcpISEef00MkC4Wm0/preview",
          question:
            "What vendor did we use for Vida, and in what month and year did the first product go live?",
          options: [
            "Modern Hire, Jan 2019",
            "Modern Hire, Jul 2018",
            "Montage, Dec 2017",
          ],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 24,
          text: "Charles",
          day: "",
          video: "charles.mp4",
          av: "https://drive.google.com/file/d/1cCcv0djoDZ1S7mkwSA8-ftmBdLtdJFpx/preview",
          question: "What type of bias was not discussed in the video",
          options: [
            "interaction bias",
            "political bias",
            "latent bias",
            "selection bias",
          ],
          validAnswers: [1],
          answerProvied: [],
        },
        {
          id: 25,
          text: "Birgit",
          day: "",
          video: "birgit.mp4",
          av: "",
          question:
            "Hoeveel collega's werken er nog bij de DF sinds de eerste groepsfoto van april 2017?",
          options: ["5", "6", "7", "8"],
          validAnswers: [2],
          answerProvied: [],
        },
        {
          id: 26,
          text: "Astrid",
          day: "",
          video: "astrid.mp4",
          av: "",
          question: "In what country did we have our first Youplan client",
          options: ["France", "Switzerland", "Italy", "Germany"],
          validAnswers: [0],
          answerProvied: [],
        },
        {
          id: 27,
          text: "Erwin",
          day: "",
          video: "erwin.mp4",
          av: "https://drive.google.com/file/d/1nl7DyicDrM9f3JIJrYq3k58BdSBumvW3/preview",
          question: "Who is the biggest geek in Randstad Digital Factory?",
          options: [
            "Some say it's Remco",
            "Erzpb. Urer, unir fbzr ebg13 rapbqvat!",
            "UmVtY28sIHN1cmVseSB5b3Uga25vdyBiYXNlNjQ=",
            "53 65 6C 65 63 74 20 61 6C 6C 21",
          ],
          allowSelect: true,
          validAnswers: [0, 1, 2, 3],
          answerProvied: [],
        },
      ],
    };
  },
  methods: {
    answerSelected(id, answerSelection) {
      let newTasks = this.tasks.map((task) =>
        task.id === id
          ? {
              ...task,
              answerProvied: answerSelection.map((key) =>
                task.options.indexOf(key)
              ),
            }
          : task
      );

      this.tasks = newTasks.map((task) =>
        task.id === id
          ? {
              ...task,
              validAnswer:
                task.answerProvied.sort().join(",") ===
                task.validAnswers.sort().join(","),
            }
          : task
      );
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/licorice.svg");
  height: 100vh;
  width: 100vw;
}

.container {
  width: 90%;
  height: 90%;
  overflow: auto;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 30px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
  }
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}
</style>
