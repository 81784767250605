<template>
  <div class="question-count">
    <div v-if="countUnansweredQuestions">
      <h3>
        {{ countQuestions - countUnansweredQuestions }} questions answered,
        {{ countUnansweredQuestions }} left
      </h3>
    </div>
    <div v-if="!countUnansweredQuestions">
      <h3>
        All {{ countQuestions - countUnansweredQuestions }} questions answered
      </h3>
    </div>
  </div>

  <div :key="task.id" v-for="task in tasks">
    <Task
      @answer-selected="onSelected"
      :task="task"
      :visible="indexDisplayed === task.id"
      :displayAnswers="displayAnswers"
    />
  </div>
</template>

<script>
import Task from "./Task";

export default {
  name: "Tasks",
  props: {
    tasks: Array,
    indexDisplayed: Number,
    displayAnswers: Boolean,
    countQuestions: Number,
    countUnansweredQuestions: Number,
  },
  methods: {
    onSelected(taskId, selection) {
      this.$emit("answer-selected", taskId, selection);
    },
  },
  components: {
    Task,
  },
  emits: ["answer-selected"],
};
</script>


<style scoped>
.question-count {
  display: inline;
  text-align: center;
  background-color: aquamarine;
}
</style>