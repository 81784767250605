<template>
  <div
    v-if="visible"
    @dblclick="$emit('toggle-reminder', task.id)"
    :class="[task.answersGiven ? 'reminder' : '', 'task']"
  >
    <div
      v-if="task.question"
      :class="[
        displayAnswers
          ? task.validAnswer
            ? 'answered-correctly'
            : 'answered-incorrectly'
          : '',
        'question-card',
      ]"
    >
      <div class="question-header">
        Question #{{ task.id + 1 }}, from
        <span class="name">{{ task.text }}</span>
      </div>

      <video controls>
        <source :src="'/static/' + task.video + '#t=0.001'" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div class="question">
        {{ task.question }}
      </div>

      <div
        v-for="(option, index) in task.options"
        :key="option"
        :class="[task.allowSelect ? '' : 'noselect']"
      >
        <label>
          <input
            type="checkbox"
            name="myCheckboxGroup"
            :value="option"
            v-model="myValue"
            :disabled="displayAnswers"
            @change="onSelected(task.id, myValue)"
          />
          <div
            :class="[
              displayAnswers
                ? task.validAnswers.includes(index) &&
                  task.answerProvied.includes(index)
                  ? 'valid'
                  : !task.validAnswers.includes(index) &&
                    task.answerProvied.includes(index)
                  ? 'invalid'
                  : task.validAnswers.includes(index) &&
                    !task.answerProvied.includes(index)
                  ? 'invalid'
                  : ''
                : '',
              ,
              'option',
            ]"
          >
            {{ option }}
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Task",
  props: {
    task: Object,
    visible: Boolean,
    displayAnswers: Boolean,
  },
  methods: {
    onSelected(taskId, option) {
      this.$emit("answer-selected", taskId, option);
    },
  },
  data() {
    return {
      that: this,
      //myValue: (this.task.answerProvied.map((index) => that.task.options[index])),
      myValue: [],
    };
  },
};
</script>

<style scope>
.fas {
  color: red;
}

.noselect {
  user-select: none;
}

.question-card {
  padding: 1rem;
  border-radius: 1rem;
  display: block;
  text-align: center;
}

.answered-correctly {
  background-color: lightseagreen;
}

.answered-incorrectly {
  background-color: lightpink;
}

.option {
  display: inline-block;
  border-radius: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 0.2rem;
  margin-bottom: 0.3rem;
}

.valid {
  background-color: green;
}

.invalid {
  background-color: red;
}

.question-header {
  display: block;
  text-align: center;
  padding-bottom: 1rem;
  font-weight: 300;
}

.name {
  font-weight: 900;
  letter-spacing: 0.05rem;
}

.question {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 900;
}

.video-container {
  display: block;
  text-align: center;
  opacity: 100%;
  max-height: 80%;
}

.task {
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .task {
    padding: 5px;
  }
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

video {
  width: 70%;
  object-fit: cover;
}
</style>
