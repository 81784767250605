<template>
  <Footer
    :countQuestions="countQuestions"
    :countUnansweredQuestions="countUnansweredQuestions"
    :allAnswered="allAnswered"
    :countAnsweredCorrectly="countAnsweredCorrectly"
    :tasks="tasks"
    :displayAnswers="displayAnswers"
    @submit-answers="submitAnswers"
  />
  <Tasks
    @answer-selected="onSelected"
    :tasks="tasks"
    :indexDisplayed="indexDisplayed"
    :displayAnswers="displayAnswers"
    :countQuestions="countQuestions"
    :countUnansweredQuestions="countUnansweredQuestions"
  />
  <Header
    @move-next="moveNext"
    @move-previous="movePrevious"
    title="A walk down the memory lane"
    :countQuestions="countQuestions"
    :countUnansweredQuestions="countUnansweredQuestions"
  />
</template>

<script>
import Tasks from "../components/Tasks";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  name: "Home",
  props: {
    showAddTask: Boolean,
    tasks: Array,
  },
  data() {
    return {
      indexDisplayed: 0,
      displayAnswers: false,
    };
  },
  computed: {
    countQuestions: function () {
      return this.tasks.length;
    },
    countUnansweredQuestions: function () {
      return this.tasks.filter((task) => task.answerProvied.length === 0)
        .length;
    },
    allAnswered: function () {
      return this.countUnansweredQuestions === 0;
    },
    countAnsweredCorrectly: function () {
      return this.tasks.filter((task) => task.validAnswer).length;
    },
  },
  components: {
    Tasks,
    Header,
    Footer,
  },
  emits: ["fetched-tasks", "answer-selected"],
  methods: {
    onSelected(taskId, selection) {
      this.$emit("answer-selected", taskId, selection);
    },
    moveNext() {
      this.indexDisplayed = (this.indexDisplayed + 1) % this.tasks.length;
    },
    movePrevious() {
      if (this.indexDisplayed) this.indexDisplayed -= 1;
      else this.indexDisplayed = this.tasks.length - 1;
    },
    submitAnswers() {
      this.displayAnswers = true;
    },
  },
};
</script>
