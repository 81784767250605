import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home'
import Intro from '../views/Intro'
import Instructions from '../views/Instructions'

const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro,
  },
  {
    path: '/instructions',
    name: 'Instructions',
    component: Instructions,
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: Home,
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
