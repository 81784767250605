<template>
  <h3>Good job!</h3>
  <p>
    Was it hard? Probably not. Hope it wasn't frustrating. Sure hope you hacked
    something, this is the way API challenges should be solved!
  </p>
  <p>
    Now for the fun part. Click "start" to proceed to a series of quiz questions
    asked by your friends. All questions are multiple choice (though, to be
    honest, most have only one valid choice). There might be one or two where
    objectively speaking it's hard to tell what's right, and there you need to
    rely on your intuition.
  </p>
  <p>
    Once you've answered all the questions click "Submit" to see your score.
  </p>
  <p>Have fun!</p>
  <p>
    <button class="btn green" @click="onClick">Lets start!</button>
  </p>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      message: "",
    };
  },
  methods: {
    onClick() {
      this.$router.push("quiz");
    },
  },
  inheritAttrs: false, // disable 'non-props' warning
};
</script>

<style scoped>
p {
  padding-bottom: 1rem;
}
h3 {
  padding-bottom: 2rem;
}
li {
  margin-left: 1rem;
  padding-bottom: 0.5rem;
  font-style: italic;
}
ul {
  padding-top: 1rem;
}

@keyframes shake {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(3em);
  }
  40% {
    transform: translate(-3em);
  }
  60% {
    transform: translate(3em);
  }
  80% {
    transform: translate(-3em);
  }
  100% {
    transform: translate(0);
  }
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}
</style>