<template>
  <h3>Congratulations!</h3>
  <p>
    Hey Remco, congratulations on your 5th anniversary with Randstad! Hope you
    are enjoying your time here. We all recorded some videos to congratulate
    you, and there is a little quiz to test how well you remember the past 5 years with us, but before we can get to this part there is something to test your
    API skills.
  </p>
  <p>
    The raspberry pi contains a service that holds the password you need to
    see the videos and play a fun game.
  </p>
  <p>Maybe you can guess the password, maybe not. It might be easier to:
      <ul>
          <li>Figure out which port the service is running on 
              (hey, you get to write a port scanner! or maybe find one that exists? 
              So that you don't spend too much time scanning for the open ports 
              here is a hint: its in the <span class="example">7000-9000</span> range. Don't scan the whole range, it may take forever to run. It's also not a a-typical port, so instead of scanning, you can try to guess!)</li>
          <li>...read the specs of the api service thats hosted there. 
              And here is another hint: a standard way to publish API 
              specs is on the same server that they are hosted, 
              in a document called <span class="example">openapi.json</span>. 
              It's not uncommon to have a <span class="example">/docs</span> document posted there as well.</li>
          <li>...ask the API nicely to get the password, and type it below.</li>
          <li>...or, hack/reverse this webpage. It's at your mercy!</li>
      </ul>
  </p>
  <p>Hope you will have some fun with it. Enjoy!</p>
  
  <div class="password-container">
      <label>
          Password:
      <input class="shaky" v-model="message" type="text" placeholder="password goes here" v-on:change="onChange($event.target)">
      </label>
  </div>
  
  <p>
      <button class="btn green" @click="onClick">Log in</button>
  </p>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      message: "",
    };
  },
  methods: {
    onClick() {
      this.$confetti.start();
      this.onChange(document.querySelector(".shaky"));
    },
    onChange(t) {
      if (this.message === "licorice") {
        this.$router.push("instructions");
      } else {
        t.classList.remove("shake");
        t.classList.add("shake");

        setTimeout(function () {
          document.querySelector(".shaky").classList.remove("shake");
        }, 1000);
      }
    },
  },
  inheritAttrs: false, // disable 'non-props' warning
};
</script>

<style scoped>
.password-container {
}

input[type="text"],
select {
  width: 15rem;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.example {
  border: 1px solid black;
  padding: 0.2rem;
}
p {
  padding-bottom: 1rem;
}
h3 {
  padding-bottom: 2rem;
}
li {
  margin-left: 1rem;
  padding-bottom: 0.5rem;
  font-style: italic;
}
ul {
  padding-top: 1rem;
}

@keyframes shake {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(3em);
  }
  40% {
    transform: translate(-3em);
  }
  60% {
    transform: translate(3em);
  }
  80% {
    transform: translate(-3em);
  }
  100% {
    transform: translate(0);
  }
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}
</style>